<template>
  <div>
    <b-card
      title="Atualizar fornecedor"
      class="text-center text-primary d-flex align-items-center"
    >
      <validation-observer ref="formUpdateSupplier">
        <b-row class="text-left mt-4 d-flex justify-content-center">
          <b-col cols="20">
            <b-form-group>
              <label
                for="username"
                class="text-center font-weight-bold text-primary"
                >Fornecedor :</label
              >
              <validation-provider
                #default="{ errors }"
                :custom-messages="{ required: 'Este campo é obrigatório' }"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="modelSupplier.supplier_name"
                  placeholder="exemplo: Kalunga"
                ></b-form-input>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <hr class="invoice-spacing mb-3" />

        <!-- Butttons -->
        <b-row class="justify-content-between d-flex p-2">
          <!-- back -->
          <b-button
            :disabled="loading"
            variant="warning"
            class="mr-2"
            @click="$router.push('/suppliers')"
            >Voltar
          </b-button>

          <!-- Salvar Button -->
          <div class="d-flex justify-content-between">
            <b-button
              variant="primary"
              @click="updateSupplier"
              :disabled="modelSupplier.supplier_name.length < 1 || loading"
            >
              <span v-if="!loading">Salvar</span>
              <b-spinner v-if="loading"></b-spinner>
            </b-button>
          </div>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'new-user',
  props: {
    editable: {
      Type: Boolean,
      default: false,
    },
    adm_id: {
      Type: String,
      default: '',
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BSpinner,
  },
  data() {
    return {
      modelSupplier: {
        supplier_name: '',
      },
      modalInactivate: false,
      loading: false,
      required,
    };
  },
  created() {
    this.getSupplier();
  },
  methods: {
    async getSupplier() {
      this.loading = true;

      const id = this.$route.params.id;
      await this.$store
        .dispatch('getSupplier', { id })
        .then((resp) => {
          this.loading = false;
          this.modelSupplier = resp;
        })
        .catch(() => {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro buscar usuário',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateSupplier() {
      this.$refs.formUpdateSupplier.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.$store
            .dispatch('updateSupplier', this.modelSupplier)
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Usuário atualizada com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$router.push({ name: 'suppliers' });
              }
            })
            .catch((err) => {
              this.loading = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${
                    err.response.data.message ==
                    'this user email is alredy exists'
                      ? 'Email Já cadastrado'
                      : err.response.data.message == 'User not found'
                      ? 'Usuário não encontrada'
                      : 'erro ao atualizar Usuário'
                  }`,
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },
  },
};
</script>
